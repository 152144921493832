import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export default function Novschedule() {
    const navigate = useNavigate();
    const classes = [
        {
            image: "images/prof.jpg",
            date: "JANUARY 27",
            title: "Professional Bakery and confectionery Course",
            href: '/festivefavorites'

        },
        {
            image: "images/breadout.jpg",
            date: "JANUARY 28",
            title: "Basics of Breads\n(1 Day Hands-On Workshop)",
            href: '/egglessbake'
        },
        // {
        //     image: "images/puff.webp",
        //     date: "Jan 28th",
        //     title: "Puff Pastry and Artisan Breads\n(3 Days Hands-On)",
        //     href: '/classicdessert'
        // },
        {
            image: "images/cookie.webp",
            date: "JANUARY 29",
            title: "Cookies\n(1 Day Hands-On Workshop)",
            href: '/sweettarts'
        },
        {
            image: "images/muffin.webp",
            date: "JANUARY 30",
            title: "Muffins\n(1 Day Hands-On Workshop)",
            href: '/viennoiseries and enriched breads'
        },
        {
            image: "images/travel.webp",
            date: "JANUARY 31",
            title: "Travel Cakes\n(1 Day Hands-On Workshop)",
            href: '/puffpastry'
        },
    ]
    const handleCardClick = (href) => {
        navigate(href);
    }
    return (
        <>
            {/* <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>November Schedule</h1>
                </div>
            </div> */}
            <div className='nov_class'>
                <div className="class-head" id="course">

                    <div className="pop-class ">
                        <h3 style={{ paddingTop: '3%', paddingBottom: '3%' }}>Whisk, Bake, and Create – January Schedule 2025 !</h3>
                        <Container>
                            <Row >
                                {classes.map((cls, index) => (

                                    <Col md={3} key={index} style={{ marginBottom: '5%' }}>
                                        <Card className="cookie november-class" style={{ border: 'none' }}
                                        // onClick={() => handleCardClick(cls.href)}
                                        >
                                            <Card.Body>
                                                <div className="image">
                                                    <Card.Img src={cls.image} className="fixed-height-img" loading="lazy" />
                                                    <div className="cls-text">
                                                        <Card.Text>
                                                            <p className="cls-date">
                                                            <i className="fa-solid fa-calendar-days" style={{ color: "#e65c8c" }}></i>&nbsp; 
                                                            {cls.date}
                                                        </p>
                                                        </Card.Text>
                                                        <Card.Title>
                                                            {cls.title.split('\n').map((line, i) => (
                                                                <h5 key={i}>{line}</h5>
                                                            ))}
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <p className="cls-para">{cls.description}</p>

                                                        </Card.Text>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                                }
                            </Row>
                        </Container>
                    </div>

                </div>
            </div>
        </>
    )
}