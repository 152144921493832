import React, { useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Cupcakes() {
    const [quantity, setQuantity] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date("2024-12-16"));
    const courseDetails = {
        name: "Viennoiseries & Enriched Breads - 3 Days Hands-On Workshop",
        // price: "Rs. 4000.00",
        date: "12, 13, 14 February 2025",
        timing: "10:00 am to 5:00 pm",
        ageCriteria: "13 years & Above",
        whatsappNumber: "9445744663"
    }
    const handleBookNow = () => {
        const dateText = selectedDate ? selectedDate.toLocaleDateString() : "No date selected";
        const message = `Course Name: ${courseDetails.name}%0ADate: ${courseDetails.date}%0ATiming: ${courseDetails.timing}%0AQuantity: ${quantity}`;
        const whatsappUrl = `https://wa.me/${courseDetails.whatsappNumber}?text=${message}`;
        window.open(whatsappUrl, "_blank");
    };
    return (
        <>
            {/* <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>Cheesecake</h1>
                </div>
            </div> */}
            <div className="cheesecake_nov">
                <div className="nov-schedule">
                    <Row style={{ margin: '0' }}>
                        <Col md={7}>
                            <div className="nov-cheesecake-img">
                                <img src="/images/vien.jpg" alt="december_cupcake_schedule" width={'100%'} loading="lazy"/>
                            </div>
                        </Col>

                        <Col md={5} className="sticky-img">
                            <div className="nov-cheesecake-details">
                                <h1>{courseDetails.name}</h1>
                                <h6>{courseDetails.price}</h6>

                                <p><strong>Date</strong></p>
                                {/* <DatePicker
                                    selected={selectedDate}
                                    // onChange={(date) => setSelectedDate(date)}
                                    dateFormat="dd MMMM yyyy"
                                    // placeholderText="Select a date"
                                    className="form-control mb-3 datepicks"
                                    disabled
                                /> */}
                                <p className="cls-date">

                                    <i className="fa-solid fa-calendar-days" style={{ color: "#000" }}></i>&nbsp;
                                    {courseDetails.date}
                                </p>
                                <p><strong>Timing:</strong></p>
                                <p className="timing"> {courseDetails.timing}</p>
                                <p><strong>Age Criteria:</strong> </p>
                                <p className="age">{courseDetails.ageCriteria}</p>

                                <Form.Group>
                                    <Form.Label><strong>Quantity</strong></Form.Label>
                                    <div className="quantity-controls">
                                        <button onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)} className="quantity-btn">
                                            −
                                        </button>
                                        <span className="quantity-display">{quantity}</span>
                                        <button onClick={() => setQuantity(quantity + 1)} className="quantity-btn">
                                            +
                                        </button>
                                    </div>
                                </Form.Group>

                                <Button variant="dark" onClick={handleBookNow} className="book-now">
                                    Book Now
                                </Button>
                                <div className="course-learn-section">
                                    <h6>Course Type</h6>
                                    <p>Beginners, Specialized Workshop.</p>
                                    <h6>About the Course</h6>
                                    <p>Step into the delicious world of buttery pastries and soft, enriched breads with our 
                                        hands-on workshop! Master the art of creating classic breakfast 
                                        treats like croissants, pain au chocolat, and brioche, along with 
                                        hearty enriched bread varieties that are perfect for every occasion. 
                                        This workshop offers the techniques and tips you need to create flaky,
                                         golden pastries and pillowy breads that taste as amazing as they 
                                         look.<br/>

Take home your handmade creations and enjoy the rewarding flavors of your baking journey with family and friends!
</p>
                                    <h6>What you will Learn</h6>
                                    {/* <p>
                                        <strong>Menu</strong>
                            
                                    </p> */}

                                    <ul>
                                        <li>Butter Croissant</li>
                                        <li>Almond Croissant</li>
                                        <li>Pain Au Chocolat</li>
                                        <li>Cinnamon Roll</li>
                                        <li>Fruit Danish</li>
                                        <li>Brioche</li>
                                        <li>Doughnuts</li>
                                        <li>Berliners</li>
                                        <li>Japanese Milk Bread</li>
                                        <li>Hot Cross Bun</li>
                                        <li>Sandwich Bread</li>
                                    </ul>

                                    <h6>What to Expect</h6>
                                    <p>A fully hands-on, practical learning experience in a well-equipped training space. Enjoy personalized, one-on-one attention, and gain insider tips and tricks for the perfect learning journey. Learn from experts dedicated to helping you master every detail. Walk away with skills and confidence to take on your baking future.</p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}