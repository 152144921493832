import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export default function DecSchedule() {
    const navigate  = useNavigate();
    const classes = [
        {
            image: "images/cup.webp",
            date: "FEBRUARY 3",
            title: "Cup Cakes\n1 Day Hands-On Workshop",
            href:'/cupcakes'
            
        },
        {
            image: "images/merin.webp",
            date: "FEBRUARY 4",
            title: "Meringues & Macarons\n1 Day Hands-On Workshop",
           href:'/meringuesandmacarons'
        },
        {
            image: "images/puff.webp",
            date: "FEBRUARY 5, 6, 7",
            title: "Puff Pastry and Artisan Breads\n3 Days Hands-On Workshop",
            href:'/puffpastry'
        },
        {
            image: "images/choux.webp",
            date: "FEBRUARY 10",
            title: "Choux Pastry\n1 Day Hands-On Workshop",
            href:'/chouxpastry'
        },
        {
            image: "images/sweet.webp",
            date: "FEBRUARY 11",
            title: "Sweet Tarts\n1 Day Hands-On Workshop",
            href:'/sweettarts'
        },
        {
            image: "images/vienno.webp",
            date: "FEBRUARY 12, 13, 14",
            title: "Viennoiseries & Enriched Breads\n3 Days Hands-On Workshop",
            href:'/viennoiseriesandenrichedbreads'
        },
        {
            image: "images/sweettartout.jpg",
            date: "FEBRUARY 17",
            title: "Savoury Tarts\n1 Day Hands-On Workshop",
            href:'/savourytarts'
        },
        
        {
            image: "images/choco.webp",
            date: "FEBRUARY 18",
            title: "Basics of Chocolate\n1 Days Hands-On Workshop",
            href:'/basicsofchocolate'
        },
        {
            image: "images/cake.webp",
            date: "FEBRUARY 19, 20, 21",
            title: "Cakes and Icing\n3 Days Hands-On Workshop",
            href:'/cakesandicing'
        },
        {
            image: "images/dessertoutside.jpg",
            date: "FEBRUARY 24, 25, 26",
            title: "Custards & Classic Desserts\n3 Days Hands-On Workshop",
            href:'/custardsandclassicdesserts'
        },
    ]
    const handleCardClick=(href) =>{
        navigate(href);
    }
    return (
        <>
            {/* <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>November Schedule</h1>
                </div>
            </div> */}
            <div className='nov_class'>
            <div className="class-head" id="course">
                
                <div className="pop-class ">
                    <h3 style={{paddingTop:'3%',paddingBottom:'3%'}}>Whisk, Bake, and Create – February Schedule 2025 !</h3>
                    <Container>
                        <Row >
                            {classes.map((cls, index) => (

                                <Col md={3} key={index} style={{ marginBottom: '5%' }}>
                                    <Card className="cookie november-class" style={{ border: 'none' }} onClick={() => handleCardClick(cls.href)}>
                                        <Card.Body>
                                            <div className="image">
                                                <Card.Img src={cls.image} className="fixed-height-img" loading="lazy"/>
                                                <div className="cls-text">
                                                    <Card.Text>
                                                        <p className="cls-date">

                                                            <i className="fa-solid fa-calendar-days" style={{ color: "#e65c8c" }}></i>&nbsp;
                                                            {cls.date}
                                                        </p>
                                                    </Card.Text>
                                                    <Card.Title>
                                                    {cls.title.split('\n').map((line, i) => (
                                                                <h5 key={i}>{line}</h5>
                                                            ))}
                                                    </Card.Title>
                                                    <Card.Text>
                                                            <p className="cls-para">{cls.description}</p>
                                                           
                                                        </Card.Text>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                            }
                        </Row>
                    </Container>
                </div>
                
            </div> 
            </div>
        </>
    )
}